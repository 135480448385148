<template>
  <div class="wrapper">
    <img loading="lazy" alt="ourteam" id="img-top" src="/images/emprendedores/ourteam.jpg" />
    <div class="background first">
      <div class="container">
        <h2>SELECCIONA LA SEDE CON LA QUE TE GUSTARÍA CONTACTAR</h2>
        <p>
          En el CLUB ponemos a tu disposición un equipo formado por
          profesionales multidisciplinares, especializados en consultoría
          y que te ayudarán a impulsar tu proyecto
        </p>
        <TeamMembers />
      </div>
      <Contacts />
    </div>
  </div>
</template>

<script>
import Contacts from '../../components/ContactsForm';
import TeamMembers from '../../components/team/TeamMembers';

export default {
  components: {
    Contacts,
    TeamMembers
  },
  metaInfo() {
    return {
      title: this.$t('emprendedores.our_team'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('emprendedores.our_team') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('emprendedores.our_team') + ' - Club de Ejecutivos' },
      ]
    }
  },
}
</script>

<style scoped lang="scss">
.wrapper {
  text-align: left;

  #img-top {
    width: 100%;
  }
}

.background.first {
  background: #f2f2f2;    
  padding: 50px 0;
}
</style>